//src/utils/request.js
import axios from 'axios'
import router from '../router/index'

// 创建 axios 实例
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
    baseURL: 'https://yasapqah.omak.top//',
    timeout: 10000, // request timeout
})

//  请求拦截器
service.interceptors.request.use(
    (config) => {
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        config.headers['Accept'] = 'application/json'
        let token = localStorage.getItem('token') || null
        let user = JSON.parse(localStorage.getItem('loginData')) || null
        if (user) {
            config.headers['userId'] = user.id
        }
        if (token) {
            config.headers['token'] = token
        }


        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        const res = response.data
            // 届时根据后端返回success或者code值判断
        if (res.success === true) {
            return res
        } else {
            return res
        }
    },
    (error) => {
        // 响应错误
        if (error.response.status === 401) {
            router.replace('/login')
            localStorage.removeItem('loginData')
            localStorage.removeItem('token')
        }
        return Promise.reject(error)
    }
)

export default service